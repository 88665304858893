import React, {useState, useEffect} from "react";
import { Accordion } from "react-bootstrap";
// import heroAppBg from "../images/hero-app-bg.svg";
// import heroAppScreen from "../images/hero-app-screen.svg";
// import heroImg from "../images/hero_img.svg";
import heroThumb from "../images/hero-thumb-1.svg";
import heroThumb1 from "../images/hero-thumb-2.svg";
import benefitsIc01 from "../images/benefits-ic-01.svg";
import benefitsIc02 from "../images/benefits-ic-02.svg";
import benefitsIc03 from "../images/benefits-ic-03.svg";
import globalExpantion from "../images/global_expantion.jpg";
import massAdaption from "../images/mass_adaption.jpg";
import ecoSystem from "../images/eco_system.svg";
import ecosystemMobile from "../images/ecosystem-mobile.svg";
import missionMakeEasyIc from "../images/mission-makeeasy-ic.svg";
import missionDormantAcIc from "../images/mission-dormant-ac-ic.svg";
import missionBrodenIc from "../images/mission-broden-ic.svg";
import missionRealWorldIc from "../images/mission-realworld-ic.svg";
import solutionIc1 from "../images/solution-ic-1.svg";
import solutionIc2 from "../images/solution-ic-2.svg";
import solutionIc3 from "../images/solution-ic-3.svg";
import solutionIc4 from "../images/solution-ic-4.svg";
import features1 from "../images/features-ic-01.svg";
import features2 from "../images/features-ic-02.svg";
import features3 from "../images/features-ic-03.svg";
import features4 from "../images/features-ic-04.svg";
import features5 from "../images/features-ic-05.svg";
import features6 from "../images/features-ic-06.svg";
import roadmapImg from "../images/roadmap-img.svg";
import roadmapMobile from "../images/roadmap-mobile.svg";
import regionsAndLicenses from "../images/regionsAndLicenses.svg";
import mahesh from "../images/mahesh.png";
import nivas from "../images/nivas.png";
import kasim from "../images/kasim.png";
import gaj from "../images/gaj.png";
import alex from "../images/alex.png";
// import twitterxIc from "../images/twitter_x_ic.svg"
import linkedInIc from "../images/linkedIn_ic.png";
// import fbIc from "../images/facebook_ic.jpg";
import pressThumb1 from "../images/press-thumb-01.jpg";
import pressThumb2 from "../images/press-thumb-02.jpg";
import dkx from "../images/partners-logo/dkx.png";
import binance from "../images/partners-logo/binance.png";
import coindcx from "../images/partners-logo/coindcx.png";
import coinSwitch from "../images/partners-logo/coinSwitch.png";
import kucoin from "../images/partners-logo/kucoin.png";
import kraken from "../images/partners-logo/kraken.png";
import standardChartered from "../images/partners-logo/standardChartered.png";
import clearJunction from "../images/partners-logo/clearJunction.png";
import idfcFirstBank from "../images/partners-logo/idfcFirstBank.png";
import bcbGroup from "../images/partners-logo/bcbGroup.png";
import incore from "../images/partners-logo/incore.png";
import yesBank from "../images/partners-logo/yesBank.png";
import sumsub from "../images/partners-logo/sumsub.png";
import chainalysis from "../images/partners-logo/chainalysis.png";
import vespia from "../images/partners-logo/vespia.png";
import grantThornton from "../images/partners-logo/grantThornton.png";
import fireblocks from "../images/partners-logo/fireblocks.png";
import appPlaystoreBtn from "../images/Google_Play_Store_badge_EN.svg";
import appStoreBtn from "../images/app-store-btn.svg";
// import playstore_qr from "../images/playstore_qr.svg";

import { Tab, Nav} from 'react-bootstrap';



function Home() {
  const handleClick = (event) => {
    event.preventDefault();
    // Do nothing
  };

  const [activeKey, setActiveKey] = useState('home');
  const handleSelect = (eventKey) => {
    setActiveKey(eventKey);
  };

  const [isFirstVisible, setIsFirstVisible] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsFirstVisible(prev => !prev);
        }, 3000); // Toggle visibility every 3000ms

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);


  return (
    <>
      <section className="bannerSec homeBanner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 order-2 order-lg-1">
              <div className="homeBanner-title">
                <div id="hero-header">
                  <div className={`heroTitle ${isFirstVisible ? 'show' : ''}`}>
                    <h1>
                      Transform Your<br/>Financial Journey Today
                    </h1>
                    <p className="text-uppercase">
                      Unlock New Opportunities with Eclipton's Innovative Web3 Solutions <span>Trading | RWA | Payments | Banking | Social Media</span>
                    </p>
                  </div>
                  <div className={`heroTitle ${isFirstVisible ? '' : 'show'}`}>
                    <h1>
                    Navigating the Globe with <br/> Proven Compliance
                    </h1>
                    <p className="text-uppercase">
                      Unlock New Opportunities with Eclipton's Innovative Web3 Solutions <span>Off ramp solutions in AED | INR | EUR | USD | SGD | CAD</span>
                    </p>
                  </div>
                </div>
                <div className="buttonSec pt-0 d-flex ">
                  <a
                    href="https://social.eclipton.com/login"
                    className="btn btn-lg primaryBtn me-2"
                  >
                    Trade Now
                  </a>
                </div>
                <div className="mt-2">
                  <p className="mb-2 pb-0">Download App</p>
                  <div className="appBtns">
                    <a className="me-2" href="https://play.google.com/store/apps/details?id=com.eclipton.eclipton&hl=en" target="_blank" rel="noopener noreferrer">
                      <img src={appPlaystoreBtn} alt="" />
                    </a>
                    <a className="" href="https://apps.apple.com/in/app/eclipton-social/id6446422998" target="_blank" rel="noopener noreferrer">
                      <img src={appStoreBtn} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 order-1 order-lg-2 text-center">
              {/* <img src={heroAppBg} className="heroAppBg" alt="" />
              <img src={heroAppScreen} className="heroAppScreen" alt="" /> */}
              {/* <img src={playstore_qr} className="playstoreQr" alt="" /> */}

              <div id="hero-header-img">
                <div className={`heroTitleImg ${isFirstVisible ? 'show' : ''}`}>
                  <img src={heroThumb} className="heroThumb" alt="" />
                </div>
                <div className={`heroTitleImg heroMapImg ${isFirstVisible ? '' : 'show'}`}>
                  <img src={heroThumb1} className="heroThumb" alt="" />
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
      <section className="secContainer80 pb-mob-0">
        <div className="container">
          <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>             
          <div className="row">
            <div className="col-lg-7 mb-4 mb-lg-0">
              <div className="bg-white p-3 p-lg-5 rounded-24 h-100 d-flex flex-column justify-content-between">
                <div className="mb-0">
                  <h1>Our Mission</h1>
                  <div className="caption">Embracing Web3 Innovation</div>
                </div>
                <div className="pt-4 ourMissionSec">
                  <div className="d-block d-md-none">
                    <div className="p-3 mb-2 align-items-center missionCardSec rounded missionCardGrey">
                      <div className="d-flex align-items-center mb-3">
                        <img src={missionMakeEasyIc} className="w-32 h-32 me-2" alt="" />
                        <div className="text-wrap">
                          <h5 className="fs-16 mb-1">Make Access Easy</h5>
                        </div>
                      </div>
                      <p className="mb-0">
                        Navigate the world of Web3 with ease. Our platform is built for both beginners and experienced users, ensuring a seamless experience for everyone. 
                        <br /><br />
                        Whether you’re trading, investing, or exploring new opportunities, Eclipton simplifies every step, making it accessible and intuitive for all, regardless of technical expertise.
                      </p>
                    </div>
                    <div className="p-3 mb-2 align-items-center missionCardSec rounded missionCardGrey">
                      <div className="d-flex align-items-center mb-3">
                        <img src={missionDormantAcIc} className="w-32 h-32 me-2" alt="" />
                        <div className="text-wrap">
                          <h5 className="fs-16 mb-1">Activate Dormant Account</h5>
                        </div>
                      </div>
                      <p className="mb-0">
                        Transform dormant crypto assets into active investments. Eclipton offers innovative solutions to revitalize inactive accounts, providing new use cases and opportunities for growth. 
                        <br /><br />
                        Our platform enables you to maximize the potential of your crypto holdings, ensuring that no asset remains underutilized, and driving continuous engagement and financial growth.
                      </p>
                    </div>
                    <div className="p-3 mb-2 align-items-center missionCardSec rounded missionCardGrey">
                      <div className="d-flex align-items-center mb-3">
                        <img src={missionBrodenIc} className="w-32 h-32 me-2" alt="" />
                        <div className="text-wrap">
                          <h5 className="fs-16 mb-1">Activate Dormant Account</h5>
                        </div>
                      </div>
                      <p className="mb-0">
                        Transform dormant crypto assets into active investments. Eclipton offers innovative solutions to revitalize inactive accounts, providing new use cases and opportunities for growth. 
                        <br /><br />
                        Our platform enables you to maximize the potential of your crypto holdings, ensuring that no asset remains underutilized, and driving continuous engagement and financial growth.
                      </p>
                    </div>
                    <div className="p-3 mb-2 align-items-center missionCardSec rounded missionCardGrey">
                      <div className="d-flex align-items-center mb-3">
                        <img src={missionRealWorldIc} className="h-28 me-2" alt="" />
                        <div className="text-wrap">
                          <h5 className="fs-16 mb-0">Drive Real-World Change</h5>
                        </div>
                      </div>
                      <p className="mb-0">
                        Lead the future of finance with tokenized real-world assets (RWAs). Eclipton bridges the gap between traditional finance and blockchain technology, making it possible for broader participation and market growth.
                        <br /><br />
                        Our platform empowers users to engage with and benefit from the digitization of real-world assets, driving tangible impact in the global economy.
                      </p>
                    </div>
                  </div>
                  <Nav variant="pills" className="flex-column d-none d-md-block" activeKey={activeKey} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link eventKey="home" onMouseEnter={() => handleSelect('home')}>
                          <div className="d-flex py-3 align-items-center missionCardSec ">
                            <img src={missionMakeEasyIc} className="w-32 h-32 me-3" alt="" />
                            <div className="text-wrap">
                              <h5 className="fs-18 mb-1">Make Access Easy</h5>
                              <p className="mb-0">Streamline Web3 trading and investment for everyone.</p>
                            </div>
                          </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="profile" onMouseEnter={() => handleSelect('profile')}>
                          <div className="d-flex py-3 align-items-center missionCardSec">
                            <img src={missionDormantAcIc} className="w-32 me-3" alt="" />
                            <div className="text-wrap">
                              <h5 className="fs-18 mb-1">Activate Dormant Account</h5>
                              <p className="mb-0">Unlock new crypto opportunities.</p>
                            </div>
                          </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="contact" onMouseEnter={() => handleSelect('contact')}>
                          <div className="d-flex py-3 align-items-center missionCardSec">
                            <img src={missionBrodenIc} className="w-32 h-32 me-3" alt="" />
                            <div className="text-wrap">
                              <h5 className="fs-18 mb-1">Broaden Our Reach</h5>
                              <p className="mb-0">Engage the 96% yet to explore crypto.</p>
                            </div>
                          </div>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="tab4" onMouseEnter={() => handleSelect('tab4')}>
                          <div className="d-flex py-3 align-items-center missionCardSec">
                            <img src={missionRealWorldIc} className="w-32 h-32 me-3" alt="" />
                            <div className="text-wrap">
                              <h5 className="fs-18 mb-1">Drive Real-World Change</h5>
                              <p className="mb-0">Lead the way in transforming RWAs.</p>
                            </div>
                          </div>
                        </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-none d-md-block">
              <div className="bg-white p-lg-4 rounded-lg h-100">
                <Tab.Content>
                  <Tab.Pane eventKey="home">
                    <div className="p-4">
                      <div className="mb-4">
                        <img src={missionMakeEasyIc} className="w-64 h-64" alt="" />
                      </div>
                      <div className="text-wrap">
                        <h5 className="fs-18 mb-lg-4 mb-2">Make Access Easy</h5>
                        <p className="mb-0">
                        Navigate the world of Web3 with ease. Our platform is built for both beginners and experienced users, ensuring a seamless experience for everyone. 
                        <br /><br />
                        Whether you’re trading, investing, or exploring new opportunities, Eclipton simplifies every step, making it accessible and intuitive for all, regardless of technical expertise.
                        </p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="profile">
                    <div className="p-4">
                      <div className="mb-4">
                        <img src={missionDormantAcIc} className="w-64 h-64" alt="" />
                      </div>
                      <div className="text-wrap">
                        <h5 className="fs-18 mb-lg-4 mb-2">Activate Dormant Accounts
                        </h5>
                        <p className="mb-0">
                        Transform dormant crypto assets into active investments. Eclipton offers innovative solutions to revitalize inactive accounts, providing new use cases and opportunities for growth. 
                        <br /><br />
                        Our platform enables you to maximize the potential of your crypto holdings, ensuring that no asset remains underutilized, and driving continuous engagement and financial growth.
                        </p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="contact">
                    <div className="p-4">
                      <div className="mb-4">
                        <img src={missionBrodenIc} className="w-64 h-64" alt="" />
                      </div>
                      <div className="text-wrap">
                        <h5 className="fs-18 mb-lg-4 mb-2">Broaden Our Reach</h5>
                        <p className="mb-0">
                        Bring the benefits of crypto to the masses. Eclipton is dedicated to engaging the 96% of individuals who have yet to explore the world of digital finance. 
                        <br /><br />
                        By providing accessible, user-friendly tools and resources, we aim to expand our reach and foster a more inclusive, global crypto community, empowering everyone to participate in the digital economy.
                        </p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab4">
                    <div className="p-4">
                      <div className="mb-4">
                        <img src={missionRealWorldIc} className="w-64 h-64" alt="" />
                      </div>
                      <div className="text-wrap">
                        <h5 className="fs-18 mb-lg-4 mb-2">Drive Real-World Change</h5>
                        <p className="mb-0">
                        Lead the future of finance with tokenized real-world assets (RWAs). Eclipton bridges the gap between traditional finance and blockchain technology, making it possible for broader participation and market growth.
                        <br /><br />
                        Our platform empowers users to engage with and benefit from the digitization of real-world assets, driving tangible impact in the global economy.
                        </p>
                      </div>
                    </div>
                  </Tab.Pane>
              </Tab.Content>
              </div>
            </div>
          </div>
          </Tab.Container>
        </div>
      </section>
      <section className="secPadd solutionsSec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-lg-5 mb-4">
                <h1>Our Solutions</h1>
                <div className="caption">Bridging the Gap Between Web2 and Web3</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="bg-white solutionsCard p-md-40 p-4 p-lg-5 rounded-lg d-flex flex-column mb-3">
                <div className="solutionsIc">
                  <img src={solutionIc1} className="size-64 me-3" alt="" />
                </div>
                <div>
                  <h4 className="fs-32">Comprehensive Ecosystem</h4>
                  <p className="text-uppercase">All-in-one platform for trading, RWA solutions, payments, social media, and banking.</p>
                  <div className="hoverShow">
                    Experience an all-in-one platform designed to meet all your digital finance needs. From trading and RWA solutions to payments, social media, and banking, Eclipton offers a comprehensive suite of services. <br />
                    Our ecosystem integrates seamlessly to provide a holistic and efficient user experience, ensuring you have everything you need at your fingertips.</div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white solutionsCard p-md-40 p-4 p-lg-5 rounded-lg d-flex flex-column mb-3">
                <div className="solutionsIc">
                  <img src={solutionIc2} className="size-64 me-3" alt="" />
                </div>
                <div>
                  <h4 className="fs-32">User-Friendly Interface</h4>
                  <p className="text-uppercase">Making crypto trading and investments easy for everyone, especially non-tech-savvy users.</p>
                  <div className="hoverShow">
                    Navigate crypto trading and investments with ease. Eclipton's user-friendly interface is tailored to simplify the complexities of digital finance, making it accessible for everyone, including non-tech-savvy users. 
                    <br />
                    Our intuitive design ensures a smooth and engaging experience, allowing you to focus on maximizing your financial potential without the hassle.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3 mb-lg-0">
              <div className="bg-white solutionsCard p-md-40 p-4 p-lg-5 rounded-lg d-flex flex-column mb-3 mb-md-0 h-100">
                <div className="solutionsIc">
                  <img src={solutionIc3} className="size-64 me-3" alt="" />
                </div>
                <div>
                  <h4 className="fs-32">Regulatory Compliance</h4>
                  <p className="text-uppercase">Working hand-in-hand with regulators to ensure full legal compliance.</p>
                  <div className="hoverShow">
                    Operate with confidence in a fully compliant environment. Eclipton prioritizes regulatory compliance by collaborating closely with regulators to ensure adherence to legal standards.
                    <br />
                    Our commitment to compliance safeguards your investments and transactions, providing peace of mind and fostering trust within our platform.  
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-white solutionsCard p-md-40 p-4 p-lg-5 rounded-lg d-flex flex-column mb-3 mb-md-0 h-100">
                <div className="solutionsIc">
                  <img src={solutionIc4} className="size-64 me-3" alt="" />
                </div>
                <div>
                  <h4 className="fs-32">Interoperability</h4>
                  <p className="text-uppercase">Working hand-in-hand with regulators to ensure full legal compliance.</p>
                  <div className="hoverShow">
                    Connect effortlessly between Web2 and Web3 ecosystems. Eclipton's platform is designed for seamless interoperability, allowing you to transition and operate across different digital landscapes without friction. 
                    <br />
                    Our solutions bridge the gap, enabling smooth integration and enhancing your overall experience in the decentralized world.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secPadd bg-white whyUsSec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-5">
                <h1>Why Us</h1>
                <div className="caption">Unlock the Future of Finance</div>
              </div>
            </div>
          </div>
          <div className="row mb-4 mb-lg-5">
            <div className="col-md-12">
              <div className="captionTitle d-flex justify-content-center">
                <span>One Stop Solution</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="benefitsCard mb-4 mb-lg-0 p-md-40 p-4 p-lg-5 rounded-lg">
                <div className="benefitCardIc">
                  <img src={benefitsIc01} className="size-48 me-3" alt="" />
                </div>
                <div>
                  <h3>AI Advisory Trading</h3>
                  <p className="mb-0">
                    Get the edge with AI-powered trading insights. Our advanced algorithms analyze market trends and provide real-time, personalized data to inform your trading decisions. <br/><br/>
                    Maximize returns and minimize risks with our AI tools. Whether you're a seasoned trader or new to the market, our solutions enhance your trading efficiency.
                  </p>
                  {/* <div>
                    <a href="javascript">Learn more</a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="benefitsCard mb-4 mb-lg-0 p-md-40 p-4 p-lg-5 benefitCardTopPos rounded-lg">
                <div className="benefitCardIc">
                  <img src={benefitsIc02} className="size-48 me-3" alt="" />
                </div>
                <div>
                  <h3>Crypto Payment Solution</h3>
                  <p className="mb-0">
                    Simplify transactions with our secure crypto payment services. Eclipton ensures fast, reliable payments using cryptocurrencies for all your financial needs. <br/><br/>
                    Enjoy the flexibility and convenience of a borderless digital economy. Our platform handles high volumes smoothly, maintaining security and efficiency.
                  </p>
                  {/* <div>
                    <a href="javascript">Learn more</a>
                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="benefitsCard mb-4 mb-lg-0 p-md-40 p-4 p-lg-5 rounded-lg">
                <div className="benefitCardIc">
                  <img src={benefitsIc03} className="size-48 me-3" alt="" />
                </div>
                <div>
                  <h3>Proven RWA Solutions</h3>
                  <p className="mb-0">
                    Unlock real-world assets with trusted tokenization. Eclipton converts diverse assets into blockchain-based tokens, enhancing liquidity and transparency.
                    <br /><br />
                    Bridge traditional assets and blockchain technology seamlessly. Our platform provides a reliable method for unlocking value and participation in the digital economy.
                  </p>
                  {/* <div>
                    <a href="javascript">Learn more</a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secPadd bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-5">
                    <h1>Eco System</h1>
                    <div className="caption">Pioneering the New Era of Digital Finance</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <img src={ecoSystem} className="mx-auto img-fluid d-none d-lg-block" alt="" />
                    <img src={ecosystemMobile} className="mx-auto img-fluid d-block d-lg-none" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="howItWorksSec bg-white d-flex align-items-end">
        <div className="container align-items-end">
          <div className="row align-items-end">
            <div className="col-md-7">
              <div className="pb-80">
                <h1 className="text-white text-5xl">Invest in the Future of Finance Today</h1>
                <p className="text-white">Lorem ipsum dolor sit amet consectetur. Interdum turpis magna vitae mi lectus amet. Amet facilisi arcu a risus pellentesque egestas pharetra. At ac nibh turpis sed interdum. At augue nibh amet nunc mauris in.</p>
                <button type="button" className="btn btn-lg btn-primary py-3 rounded-pill px-5 me-2">How it works</button>
              </div>
            </div>
            <div className="col-md-5">
              <div className="bg-white rounded-lg p-3 mb--50 shadow">
                <div className="row mb-2">
                  <div className="col-md-6 pe-1">
                    <div className="bg-gray-100 py-4 px-3 d-flex howItWorksSecBlk rounded-md align-items-center">
                      <h1 className="me-2 mb-0 font-bold fs-48">120</h1>
                      <div className="fw-500">Countriens supported</div>
                    </div>
                  </div>
                  <div className="col-md-6 ps-1">
                    <div className="bg-gray-100 py-4 px-3 d-flex howItWorksSecBlk rounded-md align-items-center">
                      <h1 className="me-2 mb-0 fs-48">2M</h1>
                      <div className="fw-500">Invested on Crypto</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pe-1">
                    <div className="bg-gray-100 p-4 d-flex h-100 howItWorksSecBlk rounded-md justify-content-center">
                      <img src={btcUsd_Ic} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6 ps-1">
                    <div className="bg-gray-100 p-4 d-flex flex-column howItWorksSecBlk rounded-md align-items-center">
                      <div className="mb-2">
                        <img src={play_circle_ic} alt="" />
                      </div>
                      <p className="mb-0 fw-500">Integer pulvinar facilisis. Nunc</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="secContainer80 featuresSec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-5">
                <h1 className="text-white">Key Features</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mobBdrBtm bdrRgt bdrBtm">
              <div className="row pe-2">
                <div className="col-md-12">
                    <div className="exchangetechSec-box p-lg-4 mb-4">
                      <span>
                        <img src={features1} alt="" />
                      </span>
                      <div className="exchangetechSec-box-title">
                        <h4>AI-Powered Trade <br/>Advisor</h4>
                      </div>
                      <p>
                        Personalized suggestions and real-time insights. Be
                        guided by cutting-edge AI algorithms.
                      </p>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mobBdrBtm bdrRgt bdrBtm">
              <div className="row px-2">
                <div className="col-md-12">
                    <div className="exchangetechSec-box p-lg-4 mb-4">
                      <span>
                        <img src={features2} alt="" />
                      </span>
                      <div className="exchangetechSec-box-title">
                        <h4>Seamless Buying and <br/>Selling</h4>
                      </div>
                      <p>
                        Real-time access to market prices. Streamlined trading
                        within the platform.
                      </p>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mobBdrBtm bdrBtm">
              <div className="row ps-2">
                <div className="col-md-12">
                    <div className="exchangetechSec-box p-lg-4 mb-4">
                      <span>
                        <img src={features3} alt="" />
                      </span>
                      <div className="exchangetechSec-box-title">
                        <h4>Dedicated<br/>Marketplace</h4>
                      </div>
                      <p>
                        Broaden your trading versatility within the ecosystem.
                      </p>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mobBdrBtm bdrRgt">
              <div className="row pt-4 pe-2">
                <div className="col-md-12">
                    <div className="exchangetechSec-box p-lg-4 mb-4">
                      <span>
                        <img src={features4} alt="" />
                      </span>
                      <div className="exchangetechSec-box-title">
                        <h4>Community-Driven Engagement</h4>
                      </div>
                      <p>
                        Personalized suggestions and real-time insights. Be
                        guided by cutting-edge AI algorithms
                      </p>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mobBdrBtm bdrRgt">
              <div className="row pt-4 px-2">
                <div className="col-md-12">
                    <div className="exchangetechSec-box p-lg-4 mb-4">
                      <span>
                        <img src={features5} alt="" />
                      </span>
                      <div className="exchangetechSec-box-title">
                        <h4>Secure Transactions with Payment Gateway</h4>
                      </div>
                      <p>
                        Seamless payments within the ecosystem and with external
                        vendors. A versatile tool for secure and hassle-free
                        transactions.
                      </p>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="row pt-4 ps-2">
                <div className="col-md-12">
                    <div className="exchangetechSec-box p-lg-4 mb-4">
                      <span>
                        <img src={features6} alt="" />
                      </span>
                      <div className="exchangetechSec-box-title">
                        <h4>Versatile Financial<br/>Solutions</h4>
                      </div>
                      <p>
                        Securely manage your digital assets with integrated AI.
                        Diversify your financial portfolio effortlessly.
                      </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secContainer">
        <div className="container">
          <div className="row align-items-md-center mb-4 mb-md-5 pb-md-5">
            <div className="col-lg-12">
                <h1>Roadmap</h1>
                <div className="caption">Embark on a journey with us as we unveil the roadmap for Eclipton's evolution</div>
            </div>
          </div>
          <div className="row mt-md-5">
            <div className="col-md-12 text-center">
              <img src={roadmapImg} className="img-fluid d-none d-lg-block" alt="" />
              <img src={roadmapMobile} className="img-fluid d-block d-lg-none" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="secContainer80 bg-white">
        <div className="container">
          <div className="row align-items-md-center mb-4 pb-4">
            <div className="col-lg-6 col-8">
                <h1 className="">
                Growth
                </h1>
                <div className="caption">Revenue Projections</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="solutionCard mb-4 mb-lg-0">
                <img src={globalExpantion} className="card-img" alt="" />
                <div className="card-img-overlay p-4 p-md-5 d-flex flex-column">
                  <h3 className="text-white">Global Expantion</h3>
                  <p className="text-white mb-0 hoverHide">Eclipton expands globally, securing key licenses and enhancing our blockchain presence.</p>
                  <div className="hoverShow">
                    Eclipton is expanding its presence globally, obtaining key licenses in strategic regions.
                    This allows us to offer advanced blockchain solutions and decentralized finance services to a wider audience, fostering innovation and financial inclusion across different markets.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="solutionCard mb-4 mb-lg-0">
                <img src={massAdaption} className="card-img" alt="" />
                <div className="card-img-overlay p-4 p-md-5 d-flex flex-column">
                  <h3 className="text-white">Mass Adaption</h3>
                  <p className="text-white mb-0 hoverHide">Making blockchain accessible for all through simplified interfaces and seamless integration.</p>
                  <div className="hoverShow">
                    Eclipton is committed to driving mass adoption of blockchain technology. By simplifying user interfaces and enhancing user experiences, we make it easier for people to participate in the digital economy. <br />
                    Our focus is on education, community engagement, and seamless integration to accelerate decentralized finance adoption.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secContainer bg-white pb-5">
        <div className="container">
          <div className="row align-items-md-center mb-md-5 pb-md-5">
            <div className="col-lg-12">
                <h1>Regions & Licenses</h1>
                <div className="caption">Global Reach with proven Compliance</div>
            </div>
          </div>
          <div className="row my-md-5">
            <div className="col-md-12 text-center">
              <img src={regionsAndLicenses} className="img-fluid" alt="" />
              <div className="row justify-content-center mt-4">
                <div className="col-md-9">
                  <p>Eclipton holds licenses in India, Estonia, and Canada, with applications in Dubai and Poland, ensuring global compliance and fostering market expansion.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secContainer80">
        <div className="container">
          <div className="row align-items-md-center mb-4 pb-4">
            <div className="col-lg-6 col-8">
                <h1 className="">
                  Our <span>Team</span>
                </h1>
                <div className="caption">Innovators in Finance and Tech</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="teamCard p-3 mb-3 mb-lg-0 text-center rounded-pill-top h-100 flex-lg-column">
                <img src={mahesh} alt="" className="img-fluid" />
                <div className="py-4">
                  <div className="teamCardTitle mb-3">
                    <h5 className="mb-0">Mahesh Kumar</h5>
                    <span>Founder & CEO</span>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <p className="fs-14">
                      Bringing over 15 years of financial expertise and a proven track record in cryptocurrency and institutional client management.
                    </p>
                    <div className="teamCardSocLinks">
                      <span className="mx-2">
                        <a href="https://www.linkedin.com/in/kumarkm1/" target="_blank" rel="noopener noreferrer"><img src={linkedInIc} alt="" /></a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="teamCard p-3 mb-3 mb-lg-0 text-center rounded-pill-top h-100 flex-lg-column">
                <img src={nivas} alt="" className="img-fluid" />
                <div className="py-4">
                  <div className="teamCardTitle mb-3">
                    <h5 className="mb-0">Nivas S</h5>
                    <span>CTO</span>
                  </div>
                  <p className="fs-14">
                    Experienced IT leader with 12+ years at Microsoft, specializing in software lifecycles, vendor management, and regulatory compliance.
                  </p>
                  <div className="teamCardSocLinks">
                    <span className="mx-2">
                      <a href="https://www.linkedin.com/in/nivas-sivaprakasam-57972128?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer"><img src={linkedInIc} alt="" /></a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="teamCard p-3 mb-3 mb-lg-0 text-center rounded-pill-top h-100 flex-lg-column">
                <img src={kasim} alt="" className="img-fluid" />
                <div className="py-4">
                  <div className="teamCardTitle mb-3">
                    <h5 className="mb-0">Kasim Khan</h5>
                    <span>COO</span>
                  </div>
                  <p className="fs-14">
                  He comes from the banking operation industry. He worked with amultinational banks like JPMorganChase Bank, The Royal Bank of Scotland and American Express
                  </p>
                  <div className="teamCardSocLinks">
                    <span className="mx-2">
                      <a href="https://www.linkedin.com/in/kasimkhan/" target="_blank" rel="noopener noreferrer"><img src={linkedInIc} alt="" /></a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="teamCard p-3 mb-3 mb-lg-0 text-center rounded-pill-top h-100 flex-lg-column">
                <img src={gaj} alt="" className="img-fluid" />
                <div className="py-4">
                  <div className="teamCardTitle mb-3">
                    <h5 className="mb-0">Gaj Kathires</h5>
                    <span>CGO</span>
                  </div>
                  <p className="fs-14 px-4">
                  E-commerce startup expert with Web3 project experience, and former VP of Global Relations at Guardianlink and OpenDB.
                  </p>
                  <div className="teamCardSocLinks">
                    <span className="mx-2">
                      <a href="https://www.linkedin.com/in/gaj-kathires/" target="_blank" rel="noopener noreferrer"><img src={linkedInIc} alt="" /></a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ourAdvisorySec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <h1 className="text-white mb-3 mb-lg-0">Our Advisory</h1>
            </div>
            <div className="col-lg-7">
              <div className="bg-white">
                <div className="d-lg-flex p-3 text-center text-lg-start">
                  <img src={alex} alt="" className="advisoryThumb" />
                  <div className="py-4 ms-4">
                    <div className="teamCardTitle mb-3">
                      <h5 className="mb-0">Alexander Caspary</h5>
                      <span>Advisor</span>
                    </div>
                    <p className="fs-14">
                      Alexander Caspary, Shareholder and Boardmember at Steubing AG and Silverasset Training, brings his extensive experience in shaping high-impact financial decisions to Eclipton.
                    </p>
                    <div className="teamCardSocLinks">
                      {/* <span className="me-3">
                        <a href="javascript"><img src={twitterxIc} alt="" className="iconX" /></a>
                      </span> */}
                      <span className="me-3">
                        <a href="#" onClick={handleClick}><img src={linkedInIc} alt="" /></a>
                      </span>
                      {/* <span className="me-3">
                        <a href="javascript"><img src={fbIc} alt="" /></a>
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secContainer80">
        <div className="container">
          <div className="row align-items-md-center mb-4 pb-4">
            <div className="col-lg-9">
                <h1 className="">
                  Our <span>Partners</span>
                </h1>
                <p>Collaborating with top-tier exchange, banking, and KYC partners to drive innovation and growth, ensuring secure, compliant, and seamless experiences for all users.</p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="captionTitle d-flex justify-content-center mb-4">
                <span>Exchange</span>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center flex-wrap partnersList">
                  <img src={dkx} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={binance} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={coindcx} className="img-fluid mx-2 mb-3" alt="" />
                </div>
                <div className="col-md-12 d-flex justify-content-center  flex-wrap partnersList">
                  <img src={coinSwitch} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={kucoin} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={kraken} className="img-fluid mx-2 mb-3" alt="" />
                </div>
                <div className="col-md-12 d-flex justify-content-center  flex-wrap partnersList">
                  <p className="fw-500">80+ Exchanges</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-7">
              <div className="captionTitle d-flex justify-content-center mb-4">
                <span>Banking</span>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3 d-flex justify-content-center flex-wrap partnersList">
                  <img src={standardChartered} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={clearJunction} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={idfcFirstBank} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={bcbGroup} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={incore} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={yesBank} className="img-fluid mx-2 mb-3" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="captionTitle d-flex justify-content-center mb-4">
                <span>KYC</span>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3 d-flex flex-wrap justify-content-center partnersList">
                  <img src={sumsub} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={chainalysis} className="img-fluid mx-2 mb-3" alt="" />
                  <img src={vespia} className="img-fluid mx-2 mb-3" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="captionTitle d-flex justify-content-center mb-4">
                <span>Auditorial</span>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3 d-flex justify-content-center flex-wrap partnersList">
                  <img src={grantThornton} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="captionTitle d-flex justify-content-center mb-4">
                <span>Custodian</span>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3 d-flex flex-wrap justify-content-center partnersList">
                  <img src={fireblocks} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secContainer faqSection bg-white">
        <div className="container">
          <div className="">
            <div className="row mb-4 mb-lg-5">
              <div className="col-lg-12">
                  <h1>FAQ</h1>
                  <div className="caption">Eclipton Explained: Your Questions Answered Here</div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                    What is Eclipton and how does it work?
                    </Accordion.Header>
                    <Accordion.Body>
                    Eclipton is a comprehensive Web3 platform that offers a range of services including trading, RWA solutions, payment gateways, social media integration, and more. It seamlessly connects Web2 and Web3 ecosystems, providing a user-friendly interface and secure transactions. Our platform leverages cutting-edge AI to offer personalized trading advice and secure management of digital assets.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                    How can Eclipton help me with my investments?
                    </Accordion.Header>
                    <Accordion.Body>
                    Eclipton offers a variety of tools to enhance your investment experience. Our AI-powered trade advisor provides personalized suggestions and real-time insights to help you make informed decisions. Additionally, our secure wallet and versatile financial solutions enable you to manage and diversify your digital assets effortlessly.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                    Is Eclipton safe to use for transactions and asset management?
                    </Accordion.Header>
                    <Accordion.Body>
                    Yes, Eclipton prioritizes security in all aspects. We have robust security measures in place, including secure transactions with our payment gateway and compliance with regulatory standards.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                    What makes Eclipton different from other crypto platforms?
                    </Accordion.Header>
                    <Accordion.Body>
                    Eclipton stands out with its comprehensive ecosystem that bridges the gap between Web2 and Web3. We offer a user-friendly interface, AI-powered trade advisory, and versatile financial solutions all in one platform. Our commitment to regulatory compliance and community-driven engagement further enhances the user experience, making Eclipton a unique and reliable choice for digital finance.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="secPadd" id="pressSec">
        <div className="container">
          <div className="row align-items-md-center mb-4 pb-4">
            <div className="col-lg-12">
                <h1 className="">
                Press
                </h1>
                <div className="caption">Where Trades Meet Intelligence, and Innovation Meets Opportunity.</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card bg-white rounded-lg overflow-hidden mb-4 mb-lg-0 border-0">
                <div className="pressCard">
                  <img src={pressThumb1} className="card-img-top rounded-t-md" alt="" />
                </div>
                <div className="card-body p-4">
                  <h5 className="card-title">Eclitpon in the news</h5>
                  <p className="card-text">In a major milestone, InvoiceMate has wrapped up a successful pilot initiative, showcasing the seamless tokenisation of an invoice using the XDC blockchain protocol. The project harnessed the power of TradeFinex's open-source smart contract standards...</p>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <a href="https://www.benzinga.com/markets/cryptocurrency/23/12/36385876/invoicemate-performs-first-private-credit-transaction-on-xdc-network-blockchain-using-trad" target="_blank" rel="noopener noreferrer" className="text-black text-decoration-none">Read more</a>
                    <a href="javascript" className="text-black text-decoration-none">26 Dec,  2023</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card bg-white rounded-lg overflow-hidden border-0">
                <div className="pressCard">
                  <img src={pressThumb2} className="card-img-top rounded-t-md" alt="" />
                </div>
                <div className="card-body p-4">
                  <h5 className="card-title">Eclitpon Facilitates Trade</h5>
                  <p className="card-text">Benzinga - In a major milestone, InvoiceMate has wrapped up a successful pilot initiative, showcasing the seamless tokenisation of an invoice using the XDC blockchain protocol. The project harnessed the power of TradeFinex's open-source smart contract standards...</p>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <a href="https://uk.investing.com/news/cryptocurrency-news/invoicemate-performs-first-private-credit-transaction-on-xdc-network-blockchain-using-tradefinex-open-source-web3-standards-3280735" className="text-black text-decoration-none" target="_blank" rel="noopener noreferrer">Read more</a>
                    <a href="javascript" className="text-black text-decoration-none">26 Dec, 2023</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* <section className="secPadd bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="bgPrime rounded-lg p-md-5">
                <h1>Get Started with Eclipton</h1>
                <p>Lorem ipsum dolor sit amet consectetur. Interdum turpis magna vitae mi lectus amet. Amet facilisi arcu tortor risus pellentesque egestas pharetra.</p>
                <div className="row">
                  <div className="col-md-8"></div>
                  <div className="col-md-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Home;
